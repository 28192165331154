/**
 * Colors
 */
$white: #fff;
$lightgrey: #c3c4c6;
$midgrey: #c3c4c6;
$turquoise: #0ac39b;
$green: #00321e;
$negative: #f45f5e;

/**
 * Text Colors
 */
$primary-color: #212833;
$active-color: #516073;
$light-text: #8ba0b9;
$selected-text: $lightgrey;
$disabled-color: #d5dbe2;
$background-color: #f1f4f8;

/**
  * Theme Colors
*/
$primary: #1c2025;
$disabled-switch: $midgrey;
$scrollbar-color: #ccd3dc;
$buttons-color: $primary-color;
$button-disabled: $disabled-color;
$button-hover: $light-text;
$icons-color: $active-color;
$inputs-color: $background-color;

// Typography
//rem units based on a root font-size of 16 px
$font-size-heading1: 2.125rem; //34px
$font-size-title: 1.8125rem; //29px
$font-size-heading2: 1.25rem; //20px
$font-size-heading3: 1.0625rem; //17px
$font-size-heading4: 0.9375rem; //15px
$font-size-body1: 0.875rem; //14px
$font-size-breadcrumbs: 0.875rem; //14px
$font-size-caption: 0.8125rem; //13px
$font-size-tips: 0.75rem; //12px
$font-size-btn-md: 1rem; //16px
$font-size-btn-lg: 1rem; //16px

//mobile font-sizes
$font-size-heading1-mobile: 2.125rem; //34px
$font-size-heading2-mobile: 1.5rem; //24px
$font-size-body1-mobile: 1rem; //16px
$font-size-heading3-mobile: 1.125rem; //18px
$font-size-heading4-mobile: 1.125rem; //18px

//font weight
$font-weight-heading1: 900; //black
$font-weight-heading2: 800; //extrabold
$font-weight-heading3: 800; //extrabold
$font-weight-heading4: 700; //bold
$font-weight-heading4-mobile: 800; //extrabold
$font-weight-body: 400; //regular
$font-weight-caption: 400; //regular
$font-weight-buttons: 700; //bold

// Borders
$default-border: 1px solid rgba(52, 53, 56, 0.3);
$modal-radius: 0;
$button-radius: 1px;

// Breakpoints
$B0: 0;
$B1: 767px;
$B2: 850px;
$B3: 1024px;

// Height breakpoint (Used for zoomed browsers)
$Z1: 480px;

// Notch safe areas
$safe-area-inset-top: env(safe-area-inset-top);
